<template>
  <main class="bg-gray-200">
    <!-- prettier-ignore -->
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <nav class="breadcrumb" aria-label="breadcrumbs">
          <ul>
            <router-link tag="li" :to="{ name: 'home' }"><a>{{ $t('breadcrumb_home') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'companies' }" class="is-hidden-mobile"><a>{{ $t('breadcrumb_companies') }}</a></router-link>
            <router-link tag="li" :to="{ name: 'company', params: { cid } }"><a><company-name :company="company"/></a></router-link>
            <router-link tag="li" :to="{ name: 'company-requests', params: { cid } }"><a>{{ $t('breadcrumb_company_requests') }}</a></router-link>
          </ul>
        </nav>
      </div>
    </section>
    <sqr-progress v-if="loading" />
    <sqr-error :error="loadError" />
    <section class="px-2 md:px-0 py-4" v-if="company">
      <div class="container">
        <div class="columns is-centered">
          <div class="column is-12-mobile is-10-tablet is-5-desktop">
            <div
              class="notification is-success is-light"
              style="background-color: #effaf3; color: #257942"
              v-if="company && pagination.page === 0 && records.length === 0"
            >
              {{ $t('company_requests_none') }}
            </div>
            <div class="field" v-for="event in records" :key="event.id">
              <div class="label is-large">
                {{ event.givenName }} {{ event.familyName }}
              </div>
              <event-card class="mb-8 hover:shadow-xl" :event="event" />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { DateTime } from 'luxon';

import company from './company';

import SqrProgress from '@/sqrd/SqrProgress';
import SqrError from '@/sqrd/SqrError';
import CompanyName from '@/components/CompanyName';
import EventCard from '@/components/EventCard';

export default {
  name: 'CompanyRequests',
  mixins: [company],
  components: { SqrProgress, SqrError, CompanyName, EventCard },
  computed: {
    ...mapState('cEvents', ['records', 'group', 'loading', 'loadError']),
    ...mapGetters('cEvents', ['pagination']),
  },
  methods: {
    ...mapActions('cEvents', ['sub', 'fieldSet']),
  },
  async mounted() {
    const today = DateTime.local().toISODate();
    this.sub({
      group: 'events',
      where: [
        ['companyId', '==', this.cid],
        ['status', '==', 'pending'],
        ['startDate', '>=', today],
      ],
      orderBy: [['startDate', 'asc']],
    });
  },
};
</script>
